<template>
  <div class="hello">
    <div class="header">
      <img src="./../assets/logo.png" alt="" />
    </div>
    <div>
      <p class="description">
        Početkom godine građani su se okupili i sakupili 38.191 potpis za
        Narodnu inicijativu kako bi zaustavili istraživanje i iskopavanje
        litijuma i bora u Srbiji, zaštitili životnu sredinu i sprečili
        raseljavanje ljudi iz ugroženih područja širom zemlje. Dok čekamo da
        Narodna skupština Republike Srbije ovu inicijativu po protokolu stavi na
        glasanje, pitali smo poslanike (svakog pojedinačno) da li će podržati
        građane ili ne. Pogledajte da li su nam odgovorili ili nas ignorišu!
      </p>
    </div>
    <div class="legend">
      <pie-chart
        height="250px"
        :donut="true"
        :colors="['#1fc481', '#EF3636', '#F67618']"
        :data="[
          ['Podržali', votedCount.yes],
          ['Nisu podržali', votedCount.no],
          ['Ignorišu', votedCount.declined],
        ]"
      ></pie-chart>
    </div>

    <h3>Sortiraj poslanike po poslaničkom klubu:</h3>
    <div class="filters">
      <div
        class=""
        v-for="party in partyFilters"
        :key="party.id"
        @click="filterByParty(party)"
      >
        <button :class="isSelected(party)" class="button">{{ party }}</button>
      </div>
    </div>
    <h3>Pretraži poslanika po imenu:</h3>
    <div class="searchMember">
      <input type="text" v-model="searchInput" class="inputSearch" />
      <button @click="deleteSearch()" class="delete">X</button>
    </div>
    <div class="members">
      <div
        class="member-card"
        v-for="parlamentMember in listRender"
        :key="parlamentMember.id"
      >
        <div class="img">
          <div v-if="parlamentMember.attributes.picture.data != null">
            <img
              class="member-img"
              :src="parlamentMember.attributes.picture.data.attributes.url"
            />
          </div>
          <div v-else>
            <img class="member-img" src="./../assets/m1.webp" />
          </div>
        </div>
        <p class="firstname-lastname">{{ parlamentMember.attributes.Name }}</p>
        <div class="member-data">
          <p class="party">
            {{ parlamentMember.attributes.Party }},
            {{ parlamentMember.attributes.City }}, ({{
              parlamentMember.attributes.Year
            }})
          </p>
        </div>
        <div v-if="parlamentMember.attributes.voted === 'yes'">
          <p class="voted v">
            <img src="./../assets/up.png" alt="" />
            Podržao/la
          </p>
        </div>
        <div v-if="parlamentMember.attributes.volunteerPic.data !== null">
          <button class="show-pic" @click="showPic(parlamentMember.id)">
            Prikaži sliku
          </button>
        </div>
        <div class="showImg" v-if="showPicId === parlamentMember.id">
          <button class="close" @click="closePic()">zatvori</button>
          <img
            :src="parlamentMember.attributes.volunteerPic.data.attributes.url"
            alt=""
          />
        </div>
        <div v-if="parlamentMember.attributes.voted === 'no'">
          <p class="voted-no v">
            <img src="./../assets/down.png" alt="" />Nije podržao/la
          </p>
        </div>
        <div v-if="parlamentMember.attributes.voted === 'declined-call'">
          <p class="voted-declined v">
            <img src="./../assets/declined.png" alt="" />IGNORIŠE.
          </p>
        </div>
        <div v-if="parlamentMember.attributes.voted == null">
          <div class="blank"></div>
        </div>
      </div>
    </div>
    <div v-if="checkShowMore()">
      <button class="showMore" @click="showMore()">Prikaži još</button>
    </div>
    <div class="footer">
      <div class="socials">
        <p>
          <a href="https://www.instagram.com/kreni.promeni/" target="_blank"
            ><img src="./../assets/insta.png" alt=""
          /></a>
        </p>
        <p>
          <a href="https://www.facebook.com/kreni.promeni/" target="_blank"
            ><img src="./../assets/facebook.png" alt=""
          /></a>
        </p>
        <p>
          <a href="https://twitter.com/kpromeni" target="_blank"
            ><img src="./../assets/twitter.png" alt=""
          /></a>
        </p>
        <p>
          <a href="https://peticije.kreni-promeni.org/" target="_blank"
            ><img src="./../assets/web.png" alt=""
          /></a>
        </p>
      </div>
      <h3>KRENI-PROMENI © 2022. All rights reserved.</h3>

      <img
        v-if="scrollFromTop > 750"
        src="./../assets/top-angle-arrow-bold-icon.png"
        @click="topFunction()"
        id="myBtn"
        title="Go to top"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "HelloWorld",
  props: {},
  data() {
    return {
      parlamentMembers: [],
      mL: 0,
      showPicId: 0,
      searchInput: "",
      partyFilters: [
        "SVI",
        "SNS",
        "DVERI",
        "DS",
        "NARODNA STRANKA",
        "JS",
        "MORAMO ZAJEDNO",
        "NADA - NOVI DSS - POKS",
        "SPS",
        "SVM",
        "UJEDINJENI",
        "ZAVETNICI",
        "SDPS",
        "PUPS",
        "NARODNI POSLANICI KOJI NISU ČLANOVI POSLANIČKIH GRUPA",
        "ZELENO-LEVI KLUB, NE DAVIMO BEOGRAD-MORAMO"
      ],
      selectedMembers: [],
      selectedParty: "",
      listLenght: 12,
      scrollFromTop: 0,
    };
  },
  computed: {
    filterMembers() {
      let filter = this.selectedParty;
      let filteredMembers = [];
      if (this.selectedParty === "SVI") {
        filteredMembers = this.parlamentMembers;
        return filteredMembers;
      } else {
        filteredMembers = this.parlamentMembers.filter(function (membmer) {
          if (membmer.attributes.Party === filter) {
            return true;
          } else {
            return false;
          }
        });
        return filteredMembers;
      }
    },
    listRender() {
      let memberlist = this.filterMembers.slice(0, this.listLenght);
      if (this.searchInput !== "") {
        return this.filterMembers.filter((item) => {
          return item.attributes.Name.toLowerCase().includes(
            this.searchInput.toLowerCase()
          );
        });
      } else {
        return memberlist;
      }
      // return memberlist;
    },
    votedCount() {
      let yes = this.selectedMembers.filter(function (membmer) {
        if (
          membmer.attributes.voted === "yes" &&
          membmer.attributes.voted !== null
        ) {
          return true;
        } else {
          return false;
        }
      }).length;
      let no = this.selectedMembers.filter(function (membmer) {
        if (
          membmer.attributes.voted === "no" &&
          membmer.attributes.voted !== null
        ) {
          return true;
        } else {
          return false;
        }
      }).length;
      let declined = this.selectedMembers.filter(function (membmer) {
        if (
          membmer.attributes.voted === "declined-call" &&
          membmer.attributes.voted !== null
        ) {
          return true;
        } else {
          return false;
        }
      }).length;
      return { yes: yes, no: no, declined: declined };
    },
  },
  watch: {
    selectedParty: function () {
      if (this.filterMembers !== null) {
        this.selectedMembers = this.filterMembers;
      }
    },
  },
  methods: {
    deleteSearch: function () {
      this.searchInput = "";
    },
    scrollFunction: function () {
      this.scrollFromTop =
        window.pageYOffset ||
        (document.documentElement || document.body.parentNode || document.body)
          .scrollTop;
    },
    topFunction: function () {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    showMore: function () {
      if (this.listRender.length <= this.listLenght) {
        this.listLenght += 12;
      }
    },
    filterByParty: function (party) {
      this.selectedParty = party;
      this.listLenght = 12;
    },
    filterByVote: function(vote){
      console.log(vote)
    },
    isSelected: function (party) {
      if (party === this.selectedParty) {
        return "active";
      } else {
        return "";
      }
    },
    checkShowMore: function () {
      if (this.listRender.length >= this.listLenght) {
        return true;
      } else {
        return false;
      }
    },
    showPic(key) {
      this.showPicId = key;
    },
    closePic() {
      this.showPicId = 0;
    },
  },
  async beforeMount() {
    await axios
      .get(
        "https://poslanici-backedn.herokuapp.com/api/parlament-members?populate=*"
      )
      .then(
        (response) =>
          (this.parlamentMembers = response.data.data.sort(function (a, b) {
            return a.id - b.id;
          }))
      );
    this.selectedParty = "SVI";
    document.addEventListener("scroll", this.scrollFunction);
    this.mL = this.parlamentMembers.lenght;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.members {
  padding: 20px 5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1%;
  row-gap: 30px;
  margin-top: 70px;
}
.members::-webkit-scrollbar {
  display: none;
}
.member {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}
.firstname-lastname {
  padding: 0;
  margin: 0;
  margin-top: 60px;
  font-size: 1.2em;
  text-transform: capitalize;
}
.member-card {
  border-radius: 5px;
  position: relative;
  padding-top: 50px;
  margin: 20px;
  border-radius: 10px;
  background: #14223b;
  color: white;
}
.blank {
  height: 40px;
  width: 100%;
  display: block;
}
.member-img {
  border-radius: 500%;
  position: absolute;
  top: 25px;
  overflow: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 15px 35px -15px rgba(0, 0, 0, 0.5);
  width: 120px;
  display: block;
}
.member-data {
  padding: 0;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 5px;
  font-family: "Nunito", sans-serif;
  /* background: yellow; */
}
.member-data p {
  font-size: 1em;
  margin: 0;
}
.hello {
  height: auto;
}
.voted {
  color: #68e4b1;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.voted i {
  font-size: 25px;
}
.voted-no {
  color: #ef3636;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.voted-no i {
  font-size: 25px;
}
.voted-declined {
  color: #F67618;
  font-weight: bolder;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  text-transform: uppercase;
}
.v i {
  font-size: 25px;
  padding: 10px;
}
.v img {
  width: 40px;
  padding: 10px;
  margin: 5px auto;
}
.header {
  margin: 0;
  border-bottom: 1px solid #c8c8c8;
  background: #213861;
  display: flex;
  justify-content: space-between;
}
.header img {
  height: 100px;
  display: block;
  margin: 0 auto;
}
.description {
  font-size: 20px;
  width: 60%;
  padding: 20px;
  margin: 20px auto;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .header img {
    height: 50px;
    display: block;
    margin: 0 auto;
  }
  .v {
    font-size: 15px;
    /* padding: 10px; */
  }
  .members {
    padding: 20px 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-gap: 1%; */
    /* row-gap: 30px; */
    margin-top: 70px;
  }
  .firstname-lastname {
    font-size: 13px;
  }
  .member-data p {
    font-size: 10px;
  }
  .member-card {
    margin: 5px;
  }
  .description {
    font-size: 14px;
    width: 90%;
  }
  .button {
    font-size: 10px;
  }
}

#myBtn {
  position: fixed;
  bottom: 5%;
  z-index: 1000;
  padding: 20px;
  width: 50px;
  right: 5%;
  /* background-color: white; */
  /* color: #14223b; */
}
#myBtn:hover {
  cursor: pointer;
}
.filters {
  display: flex;
  flex-direction: row;
  width: 90%;
  margin: 0 auto;
  justify-content: center;
  flex-wrap: wrap;
}
.button {
  padding: 15px;
  margin: 5px;
  display: blok;
  font-weight: bold;
  text-transform: uppercase;
  border: 0px solid #213861;
  border-radius: 10px;
  background: #14223b;
  color: white;
}
.filters button:hover {
  cursor: pointer;
}
.active {
  background: #efd662;
  color: #14223b;
}
.showMore {
  padding: 15px 25px;
  margin: 5px;
  display: blok;
  font-weight: bold;
  text-transform: uppercase;
  border: 0px solid;
  border-radius: 10px;
  background: #213861;
  color: white;
  font-weight: bolder;
}
.showMore:hover {
  cursor: pointer;
}
.footer {
  width: 100%;
  min-height: 100px;
  background: #213861;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  padding-bottom: 20px;
  position: relative;
}
.footer h3 {
  margin: 0;
  font-weight: 400;
  font-size: 12px;
  color: white;
}
.socials {
  display: flex;
}
.socials p {
  padding: 10px 5px;
  color: white;
}
.socials p a img {
  width: 35px;
}
.inputSearch {
  font-size: 20px;
  padding: 5px;
}
.delete {
  padding: 10px;
  margin: 5px;
  background: #ef3636;
  color: white;
  cursor: pointer;
  border: none;
}
.searchMember {
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-pic {
  padding: 10px 15px;
  font-size: 15px;
  background: #efd662;
  color: #14223b;
  font-weight: bolder;
  border: none;
  margin-bottom: 20px;
  /* margin-top: 25px; */
  border-radius: 25px;
}
.showImg {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  text-align: center;
  max-width: 100%;
  /* padding: 20px; */
  min-height: 100%;
  z-index: 10000;
  /* background: rgba(0, 0, 0, 0.445); */
}
.showImg img {
  /* display: inline-block; */
  max-width: 100%;
}
.close {
  position: absolute;
  background: #ef3636;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px 25px;
  color: white;
  font-weight: bolder;
  border:none;
  top: 20px;
  right: 20px;
}
.close:hover{
  cursor: pointer;
}
</style>
