<template>
  <HelloWorld />
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";

export default {
  name: "App",
  components: {
    HelloWorld,
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* font-family: "Inter", sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Nunito', sans-serif;
  width: 100%;

}

body{
  margin: 0;
  padding: 0;
  /* background: #eaeaea; */
  /* background-image: url('./assets/background.jpg'); */
  /* background-color: #ffffff;
  background: repeating-linear-gradient( 45deg, #fdf40e, #fdf40e 3px, #ffffff 3px, #ffffff 15px ); */
  /* opacity: 0.3; */
  background: #EDEDED;
}

html {
  scroll-behavior: smooth;
}


</style>
